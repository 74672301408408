<template>
  <textarea
    v-bind="$attrs"
    ref="textArea"
    v-model="textVal"
    type="text"
    :rows="rows"
    class="resize-none"
    :name="name"
  />
  <!-- shadow-text area need for proper resizing of the text area -->
  <textarea
    ref="shadowTextArea"
    v-model="textVal"
    type="text"
    class="shadow relative -z-1"
    :style="{ marginTop: shadowMarginTop }"
    v-bind="$attrs"
    :rows="rows"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick, type Ref, onMounted } from "vue";
import debounce from "lodash/debounce";
import { useField } from "vee-validate";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ""
  },
  name: {
    type: String,
    default: "text-area"
  },
  rows: {
    type: Number,
    default: 1
  }
});

const emit = defineEmits<{
  "update:modelValue": [value: string];
}>();

const { handleChange } = useField(props.name, undefined, {
  initialValue: props.modelValue
});

const textArea: Ref<HTMLTextAreaElement | null> = ref(null);
const textVal = ref<string>(String(props.modelValue));
const shadowTextArea: Ref<HTMLTextAreaElement | null> = ref(null);
const shadowMarginTop = computed(() => {
  // 28px is the height of one row
  // give - margin for height of row x number of rows
  const margin = (props.rows > 1 ? props.rows - 1 : 1) * 28;
  return `-${margin}px`;
});

const adjustSize = async () => {
  await nextTick();
  if (textArea.value && shadowTextArea.value) {
    textArea.value.style.height = `${shadowTextArea.value.scrollHeight}px`;
  }
};

const updateValue = debounce((value: string) => {
  handleChange(value);
  emit("update:modelValue", value);
}, 400);

watch(textVal, (newTextVal) => {
  updateValue(newTextVal);
  adjustSize();
});

watch(
  () => props.modelValue,
  (newValue) => {
    textVal.value = String(newValue);
  }
);

onMounted(() => {
  adjustSize();
});
</script>
<style scoped>
.shadow {
  pointer-events: none;
  opacity: 0;
}
</style>
